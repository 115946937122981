import { lazy } from "react";

import "./styles/vsxs.scss";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Prisoners = lazy(() => import("./pages/Prisoners"));
// const Outgo = lazy(() => import("./pages/Outgo"));
// const CashboxStatistics = lazy(() => import("./pages/CashboxStatistics"));
// const CashboxOrders = lazy(() => import("./pages/CashboxOrder"));
// const CashboxOrderSingle = lazy(() => import("./pages/CashboxOrderSingle"));

export const VSXRoutes = [
  {
    path: "/:region/dashboard",
    // element: <Income />,
    element: <Dashboard />,
  },
  {
    path: "/:region/prisoner",
    // element: <Debtors />,
    element: <Prisoners />,
  },
  {
    path: "/:region/employees",
    // element: <Outgo />,
    element: <>Tashkent employe</>,
  },
  {
    path: "/:region/cameras",
    // element: <CashboxOrders />,
    element: <>Kameralar toshkent</>,
  },
  {
    path: "/:region/store",
    // element: <CashboxOrderSingle />,
    element: <>Sklad toshkent</>,
  },
  {
    path: "/:region/archive",
    // element: <CashboxOrderSingle />,
    element: <>Arxiv toshkent</>,
  },
  {
    path: "/:region/vsx",
    // element: <CashboxStatistics />,
    element: <>Bu yerda vsxlar ro'yxati bo'ladi</>,
  },
  {
    path: "/:region/vsx/:id",
    // element: <CashboxStatistics />,
    element: <>Bu yerda har bir vsx dagi rameralar bo'ladi.</>,
  },
  {
    path: "/:region/freedom",
    // element: <CashboxOrderSingle />,
    element: <>Ozodlikka chiqarilganlar toshkent</>,
  },
  {
    path: "/:region/settings",
    // element: <CashboxOrderSingle />,
    element: <>Sozlamalar</>,
  },
  {
    path: "/:region/absence",
    // element: <CashboxOrderSingle />,
    element: <>yo;qlov</>,
  },
];
